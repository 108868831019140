body {
  background-attachment: fixed;
  background-repeat: repeat;
  position: relative;
}

.devside-logo {
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  text-transform: lowercase;
  font-size: 1.5rem;
  letter-spacing: .1em;
  color: #ffffff;
}

a {
  color: #60abae;
  text-decoration: none;
}

a:hover {
  color: #82d1d1;
  text-decoration: none;
}

.site {
  margin-left: auto;
  margin-right: auto;
}

.bg-devside {
  background-color: rgba(13, 17, 44, 1);
  filter: blur(50%);
}

.navbar-light .navbar-nav .nav-link {
  color: #ffffff !important;
}

.navbar-light .navbar-toggler {
  border: 0 solid rgba(0, 0, 0, 0);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(96,171,174, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: none;
  box-shadow: none;
}

.nav-link.active {
  border-bottom: 2px solid rgba(255, 255, 255, 0.75);
}

.nav-link {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 13px;
  letter-spacing: .1em;
  text-transform: uppercase;
}

.nav-element {
  padding-right: 24px;
  padding-left: 24px;
}

.icon-row {
  margin: 4rem 1rem;
  text-align: center
}

@media (max-width: 991px) {
  .icon-row-margin {
    margin: 1rem 0 1rem 0;
    text-align: center
  }
}

@media (max-width: 991px) {
  .icon-row-margin-2 {
    margin: 1rem -2rem 1rem -2rem;
    text-align: center
  }
}

.icon-row > .icon {
  display: inline-block;
  margin: 2rem;
  padding: 0.5rem;
}

@media (max-width: 991px) {
  .icon-row > .icon {
    margin: 0;
    margin-bottom: 1rem;
    padding: 0;
  }
}

.icon-row > .icon > .icon-btn {
  padding: 2rem;
  margin-top: 4rem;
}

.icon-row > .icon > .icon-btn {
  padding: 1rem;
  margin-top: 2rem;
}

.icon-row > .icon > .icon-btn span {
  padding: 1.2rem 1rem;
}

.icon-link:hover {
  border: 1px dashed #ffffff;
  border-radius: 4px;
  cursor: pointer;
}

.head-bg {
  background-image: url("../images/head_bg.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 320px 0px 120px 0px;
  width: 100%;
  min-height: 500px;
  position: relative;
}

#head-machine.head-bg,
#head-quality.head-bg,
#head-integration.head-bg,
#head-consulting.head-bg,
#head-agile.head-bg,
#head-process.head-bg,
#head-app.head-bg,
#head-software.head-bg {
  min-height: 500px;
}

.notch-top {
  position: absolute;
  z-index: 999;
  display: block;
  width: 70%;
  height: 50px;
  margin-top: -50px;
  margin-right: 15%;
  margin-left: 15%;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  background-color: #fff;
}

.notch-bottom {
  position: absolute;
  z-index: 999;
  display: block;
  width: 70%;
  height: 50px;
  margin-right: 15%;
  margin-left: 15%;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  background-color: #fff;
}

@media screen and (max-width: 991px) {
  .w-hidden-medium {
    display: none !important;
  }
}


@media only screen and (max-width: 600px) {
  .head-bg {
    padding: 50% 0;
    background-attachment: scroll;
  }
}

.head-block {
  color: #fff;
  padding: 0rem;
  font-family: Montserrat, sans-serif;
  display: block;
}

.head-block .head-block-title {
  margin: 1rem;
  font-size: 42px;
  line-height: 42px;
  font-weight: bold;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, .4);
}

@media (max-width: 767px) {
  .head-block .head-block-title {
    font-size: 28px;
    line-height: 42px;
  }
}

@media (max-width: 991px) {
  .head-block .head-block-title {
    font-size: 1.6rem;
    line-height: 42px;
  }
}

.head-block .head-block-body {
  margin: 1rem;
  font-size: 22px;
  line-height: 33px;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, .4);
  max-width: 800px;
}

.container {
  max-width: 1500px;
}

.inner-container {
  max-width: 1300px;
  margin: 0 auto;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.section {
  padding: 6rem;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  letter-spacing: .03em;
  line-height: 1.6em;
  text-transform: none;
  color: #000;
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .section {
    padding: 0;
  }
}

.section-picture-left {
  float: left;
  margin-right: 1rem;
}

.section-picture-right {
  float: right;
  margin-left: 1rem;
}

.section-header {
  margin: 1.5rem 0;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  font-size: 40px;
  font-style: normal;
  letter-spacing: 0em;
  text-transform: none;
  line-height: 1.1em;
}

.section-body {
  overflow: hidden;
  padding: 2rem;
}

.section p > strong {
  color: #3aafb2;
}

.section.fullbox-dark p > strong {
  color: #ffffff;
}

.tab-contents p > strong {
  font-size: 2rem;
}

.section .contact {
  margin: 0 1rem;
}

.section.fullbox-dark {
  background-color: #3aafb2;
  padding: 6rem 0 4rem 0;
  color: #fff;
  background-image: url("../images/section-bg.png");
  background-size: 125%;
  background-attachment: fixed;
  background-repeat: repeat;
  background-position: -20% 40%;
  position: relative;
}

.trianglenoth-bottom {
  width: 0;
  height: 0;
  border-top: 100px solid transparent;
  border-right: 98vw solid white;

  background-image: url("../images/section-bg.png");
  background-size: 125%;
  background-attachment: fixed;
  background-repeat: repeat;
  background-position: -20% 40%;
  position: relative;
}

.trianglenoth-top {
  width: 0;
  height: 0;
  border-bottom: 100px solid transparent;
  border-right: 98vw solid white;

  background-image: url("../images/section-bg.png");
  background-size: 125%;
  background-attachment: fixed;
  background-repeat: repeat;
  background-position: -20% 40%;
  position: relative;
}

.section.fullbox {
  background-color: #3aafb2;
  padding: 6rem;
}

.section.fullbox-dark .fullbox-header,
.section.fullbox .fullbox-header {
  color: #ffffff;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 48px;
  letter-spacing: 0em;
  line-height: 1.2em;
}


.section.fullbox-dark .fullbox-body
.section.fullbox .fullbox-body {
  color: #ffffff;
}

.team-info {
  color: #ffffff;
  text-align: center;
}

.team-info p > strong {
  color: #ffffff;
}

.team-block {
  position: relative;
}

.team-img img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
}

.form-control {
  border-radius: 0;
  background-color: #FAFAFA;
}

.privacy > input {
  float: left;
  margin-top: 8px;
  margin-right: 5px;
}

.pricacy > span {
  float: right;
}

.footer {
  margin: 0 0;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  letter-spacing: .03em;
  line-height: 1.6em;
  text-transform: none;
  color: #000;
  overflow: hidden;
  overflow: hidden;
  padding: 1rem;
}

.btn-dev {
  color: #fff;
  background-color: #3aafb2;
  border-color: #3aafb2;
  border-radius: 0.25rem;
  padding: 1rem;
}

.tabs {
  margin: 4rem auto;
  padding: 0 20px;
}

.tab-button {
  display: table;
  table-layout: fixed;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  cursor: pointer;
}

.tab-button li {
  display: table-cell;
  width: 20%;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
}

.download-box {
  border: 1px solid #c0c0c0;
  border-radius: 0.5rem;
  padding: 0.75rem 0;
}
@media only screen and (max-width: 600px) {
  .download-box {
    border: 0;
  }
}

.no-hover:hover {
  border: 0 !important;
  margin: 0 !important;
  cursor: default !important;
}

.tab-button li a {
  display: block;
  margin: 0 0.5rem;
  padding: 0.5em;
  background: #3aafb2;
  border: 2px solid #3aafb2;
  -webkit-border-top-left-radius: 0.2rem;
  -webkit-border-top-right-radius: 0.2rem;
  -moz-border-radius-topleft: 0.2rem;
  -moz-border-radius-topright: 0.2rem;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  text-align: center;
  color: #fff;
  text-decoration: none;
}

.tab-button .is-active a {
  border-bottom-color: transparent;
  background: #fff;
  color: #000;
}

.tab-button li.is-active a:hover{
  background-color: #fff;
  color: #000;
}

.tab-button li:hover
{
  color: #fff;
}

.tab-button li a:hover
{
  background: #318a8d;
  color: #fff;
}

.tab-contents {
  padding: 1em 2em 1em;
  border: 2px solid #3aafb2;
  border-radius: 0.2rem;
  background: #fff;
}

.tab-contents .tab-contents-header {
  font-size: 2rem;
}

.tab-button-outer {
  display: none;
}
.tab-contents {
  margin-top: 20px;
}

@media screen and (min-width: 1070px) {
  .tab-button-outer {
    position: relative;
    z-index: 2;
    display: block;
  }

  .tab-select-outer {
    display: none;
  }

  .tab-contents {
    position: relative;
    top: -2px;
    margin-top: 0;
  }
}

.app-entry {
  margin: 4rem 1rem;
}

.smartphone {
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.indicator-arrow {
  display: inline;
  font-size: 3rem;
  cursor: pointer;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.smartphone-border {
  border: 8px solid black;
  border-radius: 3rem;
}

@media only screen and (max-width: 600px) {
  .smartphone-border {
    border: 8px solid black;
    border-radius: 3rem;
    width: 100%;
    align-self: center;
  }
}

.reel-indicator {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dot {
  margin: 4px;
  height: 10px;
  width: 10px;
  background-color: #bbbbbb;
  border-radius: 50%;
}

.dot.active {
  margin: 4px;
  height: 10px;
  width: 10px;
  background-color: #585858;
  border-radius: 50%;
}

#map {
  width: 100%;
  height: 570px;
}

#desktop-apps { display: block; }
#mobile-apps { display: none; }

@media only screen and (max-width: 1308px) {
  #desktop-apps { display: none; }
  #mobile-apps { display: block; }
}

#app-entry-finance-mobile,
#app-entry-maps-mobile,
#app-entry-chat-mobile,
#app-entry-pay-mobile {
  margin: 1rem 1rem;
  font-size: 1rem;
}

#app-entry-finance-mobile p > strong,
#app-entry-maps-mobile p > strong,
#app-entry-chat-mobile p > strong,
#app-entry-pay-mobile p > strong {
  font-size: 1.5rem;
}

.dark-tile {
  background-color: #61BFC1;
  border: 1px solid #127c80;
  padding: 1rem;
  width: 100%;
  height: 100%;
  color: #fff;
}

.light-tile {
  background-color: #9BD7D7;
  border: 1px solid #127c80;
  padding: 1rem;
  width: 100%;
  height: 100%;
  color: #fff;
}

.dark-tile > strong,
.light-tile > strong {
  font-size: 1.2rem;
  text-align: justify;
}

.float-button {
  position: fixed;
  padding: 10px 20px;
  bottom: 40px;
  right: 40px;
  background-color:#127c80;
  color: #fff;
  border-radius: 8px;
  text-align: center;
}

.app-entry-desktop {
  padding: 1rem;
}

.app-entry-desktop.active {
  border: 1px dashed rgb(216, 216, 216);
}

#desktop-apps .row {
  padding-bottom: 15px;
  padding-top: 15px;
}

#mobile-apps .row {
  padding-bottom: 7.5px;
}

#mobile-apps .row .col-md-3 {
  padding-bottom: 15px;
}

#etFinance .row,
#dtFinance .row {
  padding-bottom: 7.5px;
}

#etFinance .row .col-md-6,
#dtFinance .row .col-md-6 {
  padding-bottom: 15px;
}

.fixed-height-entry {
  height: 10rem;
}

.more-link {
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  bottom: 0.5rem;
  right: 1.5rem;
  position: absolute;
  background-color: #D90D56;
  padding: 0 1rem;
  border-radius: 0.2rem;
}

.mid-header {
  font-size: 1.5rem;
  font-weight: bold;
  color: #318a8d;
}

.circle-container {
  position: relative;
  width: 24em;
  height: 24em;
  padding: 2.8em;
  border-radius: 50%;
  margin: 1.75em auto 0;
}

.feature-block {
  position: relative;
  padding: 1rem;
  padding-left: 9rem;
  color: #1a1a1a;
  line-height: 26px;
}

@media only screen and (max-width: 600px) {
  .feature-block {
    padding-left: 0;
  }
}

.feature-block:hover {
  border: 1px dashed rgb(216, 216, 216);
  margin: -1px 0 0 -1px;
  cursor: pointer;
}

.feature-icon {
  position: absolute;
  left: 1rem;
  top: 1rem;
}

@media only screen and (max-width: 600px) {
  .feature-icon {
    position: static;
  }
}

.feature-title {
  margin-bottom: 12px;
  font-family: Montserrat, sans-serif;
  color: #1a1a1a;
  font-size: 21px;
  font-weight: 600;
}

.feature-title-2 {
  font-size: 24px;
  color: black !important;
}

.feature-paragraph {
  margin-bottom: 0px;
  font-family: Montserrat, sans-serif;
  color: #1a1a1a;
  font-size: 0.8em;
  line-height: 1.8em;
  text-align: justify;
}

.feature-row {
  margin: 4rem 0;
}

.feature-row-8 {
  margin-top: 8rem !important;
}

.row.display-flex {
  display: flex;
  flex-wrap: wrap;
}

.row.display-flex > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

.block-text-flow {
  text-align: justify;
}

.img-max {
  max-width: 1400px;
}

.img-max-light {
  max-width: 1000px;
}

.img-max-height {
  max-height: 800px;
}

.dropdown-menu {
  background-color: rgba(13, 17, 44, 1);
  border: 2px solid rgba(13, 17, 44, 1);
  border-radius: 0;
  margin-top: -0.125rem;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #fff;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 13px;
  letter-spacing: .1em;
  text-transform: uppercase;
}

.dropdown-item.active {
  background-color: transparent;
  border-bottom: 2px solid rgba(255, 255, 255, 0.75);
}

.dropdown-item.active:hover {
  background-color: #fff;
  border-bottom: 2px solid rgba(255, 255, 255, 0.75);
  color: #000;
}